<template>
  <div class="dashboard">
    <template v-if="loggedIn && userData">
      <div class="module">
        <div class="module-head">
          <h3 class="title">
            Welcome {{ realUserData.name }} {{ realUserData.surname }}!
          </h3>
        </div>
        <div class="module-body">
          <div
            :class="error.length > 0 ? 'form-error' : 'form-success'"
            v-if="error.length > 0 || resultMsg.length > 0"
          >
            <span>{{ error ? error : resultMsg }}</span>
          </div>
          <div class="form-row">
            <span>PIN Code</span>
            <input disabled type="text" v-model="realUserData.pin_code" />
            <button @click="renewPinCode">Renew</button>
          </div>
          <div class="form-row">
            <span>Public Visibility</span>
            <template v-for="item in publicVisibilityOptions" :key="item.value">
              <label
                :class="[item.value == userData.is_public ? 'active' : '']"
              >
                <input
                  type="radio"
                  v-model="userData.is_public"
                  :value="item.value"
                />
                <span>{{ item.label }}</span>
              </label>
            </template>
            <button class="invisible-forming"></button>
          </div>
          <div class="form-row">
            <span>Public Name</span>
            <input
              type="text"
              :value="`${userData.name} ${userData.surname}`"
              disabled
            />
            <button @click="setName">Change</button>
          </div>
          <div class="form-row">
            <button @click="resetData">Reset</button>
            <button @click="reserve">Withdraw Packages</button>
            <button @click="saveData">Save</button>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <h3 class="title">{{ message }}</h3>
    </template>

    <div class="modal-backdrop" v-if="modalShow">
      <div class="modal">
        {{ modalText }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.module {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  width: 100%;

  .module-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    background: linear-gradient(#fff, #f4f4f4);
    border: 1px solid #ebebeb;
    border-bottom: none;
    border-radius: 5px 5px 0 0;
    color: #222;
  }

  .module-head h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
  }

  .module-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    background: #fff;
    border: 1px solid #ebebeb;
    border-radius: 0 0 5px 5px;
  }
}
.form-error {
  font-size: 16px;
  padding: 5px;
  width: 100%;
  font-family: "Anton";
  background-color: rgba(red, 0.5);
  color: #222;
}
.form-success {
  font-size: 16px;
  padding: 5px;
  width: 100%;
  font-family: "Anton";
  background-color: rgb(green, 0.5);
  color: #222;
}

.form-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0;

  > span {
    font-size: 18px;
    font-weight: 400;
    margin-right: 10px;
  }

  input {
    width: 50%;
    padding: 10px;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    font-size: 18px;
  }

  button {
    padding: 10px 20px;
    border: 1px solid #ff9900;
    border-radius: 5px;
    font-size: 18px;
    background: #ff9900;
    color: #f4f4f4;
    cursor: pointer;
  }

  label {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ebebeb;
    text-align: center;

    &.active {
      background: #ff9900;
      color: #f4f4f4;
    }

    input {
      visibility: hidden;
      position: absolute;
    }
  }
}

button.invisible-forming {
  visibility: hidden;
}

.module {
  transform: scale(1.75);
  transform-origin: top;
}

.modal-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.modal {
  background-color: #333;
  color: #fff;
  border: 1px solid #222;
  padding: 20px;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  font-size: 32px;
}
</style>

<script>
import lockerConfig from "@/lockerConfig";
import service from "@/api/service";
const apiService = service;

export default {
  name: "UserDashboard",
  data() {
    return {
      loggedIn: false,
      message: "Please wait until we log you in...",
      token: "",
      userData: {},
      realUserData: {},
      publicVisibilityOptions: [
        { value: 0, label: "Private" },
        { value: 1, label: "Public" },
      ],
      modalShow: false,
      modalText: "",
      error: "",
      resultMsg: "",
    };
  },
  mounted() {
    this.token = this.$route.query.accessToken;
    if (!this.token) {
      this.message = "Sorry, we could not log you in.";
      return;
    }

    this.getUserData();
  },
  methods: {
    pollDrawer: async function (drawerId, maxTry = 30, errorCount = 0) {
      if (maxTry <= 0) {
        return false;
      }

      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const status = await apiService.getSolenoidStatus(drawerId);
        if (status == false) {
          return true;
        }

        return await this.pollDrawer(drawerId, maxTry - 1, errorCount);
      } catch (err) {
        if (errorCount >= 15) {
          return false;
        }

        return await this.pollDrawer(drawerId, maxTry, errorCount + 1);
      }
    },
    pollDrawerOpen: async function (drawerId, maxTry = 30, errorCount = 0) {
      if (maxTry <= 0) {
        return false;
      }

      try {
        await new Promise((resolve) => setTimeout(resolve, 500));
        const status = await apiService.getSolenoidStatus(drawerId);
        if (status == true) {
          return true;
        }

        return await this.pollDrawerOpen(drawerId, maxTry - 1, errorCount);
      } catch (err) {
        if (errorCount >= 15) {
          return false;
        }

        return await this.pollDrawerOpen(drawerId, maxTry, errorCount + 1);
      }
    },
    reserve: async function () {
      let barcode = this.userData.pin_code;

      if (barcode.length <= 0) {
        this.error =
          "Pin code cannot be retrieved. Make sure it is set in your profile.";
        return;
      }

      const confirmed = await this.$swal.fire({
        title: "Are you sure?",
        text: "This will open all the drawers with your packages.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, continue!",
      });

      if (!confirmed.isConfirmed) {
        return;
      }

      try {
        const result = await apiService.getAllUserPackagesByCode(barcode);

        if (!result) {
          throw "No package found!";
        }

        if (result.length <= 0) {
          this.resultMsg = "No packages available to withdraw.";
          return;
        }

        console.log(result);

        this.loadStatus = true;

        // if (!openedDrawers) {
        //   this.error = "";
        //   this.resultMsg = "No awaiting packages are found for you.";
        //   return;
        // }

        this.resultMsg = `Found ${result.length} packages, please wait until the drawers are opened...`;

        await new Promise((resolve) => setTimeout(resolve, 3000));

        let openedDrawers = [];

        for (let i = 0; i < result.length; i++) {
          const packageData = result[i];
          const drawerId = packageData.drawer_id;
          const drawerData = await apiService.getDrawer(drawerId);

          if (drawerData.blackout_state == 1) {
            this.resultMsg = "";
            this.error = `Drawer ${drawerId} is currently in blackout state, please try getting that package later. You will now receive the rest of the packages.`;

            await new Promise((resolve) => setTimeout(resolve, 3000));
            continue;
          }

          try {
            const open = await apiService.openDrawer(drawerId);
          } catch (err) {
            this.resultMsg = "";
            this.error =
              "System error while opening a drawer, please contact system administrator.";
            continue;
          }

          const pollOpen = await this.pollDrawerOpen(drawerId);
          if (!pollOpen) {
            this.resultMsg = "";
            this.error =
              "A drawer is not opened, please try again later or contact system administrator.";
            continue;
          }

          openedDrawers.push(drawerId);

          await new Promise((resolve) => setTimeout(resolve, 500));
        }

        if (openedDrawers.length == result.length) {
          this.resultMsg =
            "All drawers have been opened. Please take your packages.";
        } else {
          this.resultMsg =
            "Some drawers couldn't be opened. Please take your packages and try again later to get other packages.";
        }

        await new Promise((resolve) => setTimeout(resolve, 5000));

        if (!openedDrawers) {
          this.resultMsg = "";
          this.error =
            "No drawers are opened. Please contact system administrator.";

          setTimeout(() => {
            this.error = "";
          }, 5000);
          return;
        }

        let closedDrawers = [];

        openedDrawers.forEach(async (drawerId) => {
          const status = await this.pollDrawer(drawerId);

          if (!status) {
            return;
          }

          closedDrawers.push(drawerId);
        });

        if (openedDrawers.length == 0) {
          this.resultMsg = "";
          this.error =
            "No drawers are opened. Please contact system administrator.";

          setTimeout(() => {
            this.error = "";
          }, 3000);
          return;
        }

        let trials = 0;
        let errorOnClosing = false;

        while (closedDrawers.length < openedDrawers.length) {
          if (trials >= 10) {
            errorOnClosing = true;
            break;
          }

          await new Promise((resolve) => setTimeout(resolve, 3000));
          trials++;
        }

        if (errorOnClosing) {
          this.error = "";
          this.resultMsg = "Please close all the open drawers.";
        }

        if (!errorOnClosing) {
          this.error = "";
          this.resultMsg = "All packages are received successfully.";
        }

        // Send a receive request for each package
        result.forEach(async (packageData) => {
          // If the drawer is not opened, return.

          const drawerData = await apiService.getDrawer(packageData.drawer_id);

          if (!openedDrawers.includes(packageData.drawer_id)) {
            return;
          }

          const packageId = packageData.id;
          const receiveResult = await apiService.receiveByPackageId(packageId);
        });
      } catch (error) {
        console.log(error);
        this.error = "There is a system error occured. Please try again later.";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }

      return;
    },
    getUserData: async function () {
      try {
        const userData = await service.getUserData(this.token);
        if (userData) {
          this.userData = { ...userData };
          this.realUserData = { ...userData };

          if (this.$route.query.firstName) {
            this.userData.name = this.$route.query.firstName;
          }

          if (this.$route.query.lastName) {
            this.userData.surname = this.$route.query.lastName;
          }

          this.loggedIn = true;
        } else {
          this.message = "Sorry, we could not log you in.";
        }
      } catch (error) {
        this.message = "Sorry, we could not log you in.";
      }
    },
    resetData: function () {
      this.userData = { ...this.realUserData };
    },
    renewPinCode: async function () {
      this.modalShow = true;
      this.modalText = "Please wait while we renew your pin code...";

      try {
        const pinCode = await service.renewPinCode(this.token);
        if (pinCode) {
          this.userData.pin_code = pinCode;
          this.realUserData.pin_code = pinCode;
          this.modalText = "Your pin code has been renewed.";
        } else {
          throw true;
        }
      } catch (error) {
        console.log(error);

        this.modalText = "Sorry, we could not renew your pin code.";
      }

      setTimeout(() => {
        this.modalShow = false;
      }, 2000);
    },
    saveData: async function () {
      this.modalShow = true;
      this.modalText = "Please wait while we save your data...";

      try {
        const userData = await service.updateUserData(
          this.token,
          this.userData.name,
          this.userData.surname,
          this.userData.is_public
        );
        if (userData) {
          this.realUserData = { ...userData };
          this.userData = { ...userData };
          this.modalText = "Your data has been saved.";
        } else {
          throw true;
        }
      } catch (error) {
        console.log(error);

        this.modalText = "Sorry, we could not save your data.";
      }

      setTimeout(() => {
        this.modalShow = false;
      }, 2000);
    },
    setName: function () {
      this.$router.push({
        name: "UserDashboard/SetName",
        query: {
          accessToken: this.token,
        },
      });
    },
  },
};
</script>
